import * as React from 'react'
import { useEffect } from 'react'
import { INode } from '../../types/mdx'
import SeriesListItem from '../series-list-item'
import * as styles from './index.module.scss'

interface IGroupItem {
  fieldValue: string
  totalCount: number
  edges: INode[]
}

interface IProps {
  groupItems: IGroupItem[]
  maxTotalCount: number
  selectedSeries: string | undefined
  onSelectedSeries: (tag: string | undefined) => void
}

function SeriesList({
  groupItems,
  maxTotalCount,
  selectedSeries,
  onSelectedSeries,
}: IProps) {
  useEffect(() => {
    onSelectedSeries(selectedSeries)
  }, [selectedSeries])

  return (
    <div className={styles.seriesListWrap}>
      <ul role='tablist'>
        {groupItems.map(({ fieldValue: seriesName, totalCount }) => (
          <SeriesListItem
            key={seriesName}
            isSelected={seriesName === selectedSeries}
            series={seriesName}
            totalCount={totalCount}
            maxTotalCount={maxTotalCount}
            onSelectedSeries={onSelectedSeries}
          />
        ))}
      </ul>
    </div>
  )
}

export default SeriesList
